.ods-container{
    margin-top: 64px;
    min-height: calc(100vh - 98px);
    padding: 48px 20px 60px;
    background-color: #343033;
    color: white;
}
.ods-container h2{
    color: #aff02e;
    text-align: center;
    margin-bottom: 48px;
}
.ods-container p{
}
.ods-container span{
    color: #aff02e;
    font-weight: bold;
    font-style: italic;
}
