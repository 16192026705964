.spinner-container{
  position: relative;
  width: 100%;
  margin: 0 auto;

}

.div-spinner {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  width: 90%;
  max-width: 500px;
}
.spinner {
    animation: exp 5s infinite;
  width: 100%;
}
@keyframes exp {
  0% {
    transform: scale(0%);
  }
  50% {
    transform: scale(100%);
  }
  100% {
    transform: scale(0%);
  }
}
