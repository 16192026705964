:root {
  --color1: #aff02e;
  --color2: #343033;
  --color3: #12b318;
  --color4: #131313;
  --color5: #b3b3b1;
  --color7: #4c4c4c;
}
.main-admin-panel {
  padding: 92px 20px 40px;
  color: white;
  background-color: var(--color2);
}
.main-admin-panel h1 {
  color: var(--color1);
  text-align: center;
  margin-bottom: 12px;
}
.admin-page-users-container{
  /* max-width: 1024px; */
  margin: 0 auto;
}
.title-card-admin{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

}
.title-card-admin h2 {
  font-style: italic;
  font-size: 24px;
}
.title-card-admin a{
  font-size: 20px;

}
.content-admin-card{
    border: 1px solid var(--color1);
    padding: 4px;
    margin-bottom: 16px;
}
.table {
  width: 100%;
  max-height: 600px;
  overflow: scroll;
}
table{
  border-spacing: 0;
  width: 100%;
}
th{
    color: var(--color1);
    font-size: 18px;
    padding: 4px 8px;
    border: 1px solid white;
}
.check-icon{
    text-align: center;
}
.check-null{
    color: var(--color4);
}
.check-review{
    color: yellow;
}
.check-return{
    color: red;
}
.check-complete{
    color: var(--color1);
}
td {
  /* width: 60px; */
  height: 100%;
  border: 1px solid white;
  padding: 4px 8px;
}
.td-number-activity{
  color: var(--color1);
}
.img-card-admin {
  width: 40px;
  height: 64px;
  overflow: hidden;
}
.img-card-admin img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.action-card-admin {
  /* width: 100%; */
  
  /* gap: 8px; */
  /* padding: 4px; */
  /* border: none; */
}
.table-code-admin td{
  text-align: center;
}
.svgQR{
  display: flex;
  justify-content: center;
  padding: 4px;
}
.action-card-admin svg {
  height: 20px;
}
.button-panel-admin-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:  8px;
  height: 100%;
  padding: 8px;
}
.button-panel-admin-activity{
  background-color: var(--color1);
  width: 60px;
  color: black;
  border-radius: 4px;
  cursor: pointer;
}
@media(min-width: 720px){
  .main-admin-panel {
    padding: 92px 60px 40px;
    color: white;
    background-color: var(--color2);
  }
}
@media(min-width: 1024px){
  .main-admin-panel {
    padding: 100px 60px 40px;
  }
}
@media(min-width: 1440px){
  table{
    margin: 0 auto;
  }
}