.section-conditions {
  background-color: rgba(0, 0, 0, 0.829);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 48px);
  max-height: calc(100% - 180px);
  overflow-y: scroll;
  border-radius: 8px;
  box-shadow: 0px 0px 20px -6px white, inset 0px 0 20px -4px black;
  color: white;
  padding: 20px;
  z-index: 8;
}
.section-conditions h2 {
  font-size: 20px;
  text-align: center;
  margin-bottom: 12px;
  color: #aff02e;
}
.condition-p {
  text-align: justify;
  margin-bottom: 16px;
}
.condition-p span {
  color: #aff02e;
  font-weight: bold;
  font-style: italic;
  text-align: start;
  font-size: 16px;
}
.condition-content p {
}
@media (min-width: 720px) {
  .section-conditions {
    width: calc(100% - 96px);
    padding: 32px;
  }
  .section-conditions h2 {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .condition-content p {
    font-size: 20px;
  }
}
@media (min-width: 1024px) {
  .section-conditions {
    max-height: calc(100vh - 236px);
  }
}
@media (min-width: 1440px) {
  .section-conditions {
    width: 60%;
    padding: 60px;
  }
  .section-conditions h2 {
    margin-bottom: 40px;
  }
}
