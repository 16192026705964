.title-send-email{
    font-size: 18px;
    text-align: center;
    margin-bottom: 8px;
}
.user-password-container{
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.user-password-container h2{
    text-align: center;
    margin: 12px 0 24px;
}
.user-password-img-container{
    width: 50vw;
    height: 50vw;
    max-width: 340px;
    max-height: 340px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 0px 12px 0px #aff02e;

}
.user-password-img-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}