
.panel-chat-content {
  background-color: rgb(92, 111, 89);
  height: 372px;
  width: 100%;
  border-radius: 20px;
  box-shadow: inset 0 0 12px 0 black;
}

.section-panel-chat {
  max-width: 280px;
  height: 500px;
  background-color: black;
  color: white;
  padding: 24px;
  border-radius: 40px 40px 40px 0;
  box-shadow: inset 0 0 20px -4px white;
}
.header-chat-into-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.img-chat-into-container-relative {
  position: relative;
  height: 70px;
  margin-right: 0px;
}
.img-chat-into-container{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #aff02e;
  box-shadow: 0 0 8px 0 white;
  overflow: hidden;
}
.img-chat-into-container img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.section-chat-into {
  position: absolute;
  top: 8px;
  left: 8px;
  max-width: 264px;
  height: 484px;
  background-color: black;
  color: white;
  padding: 24px;
  border-radius: 40px 40px 40px 0;
  box-shadow: inset 0 0 20px -4px white;
}

.buttom-close-chat{
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.buttom-close-chat svg{
  width: 100%;
  height: 100%;
}
.img-panel-chat-container{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #aff02e;
  box-shadow: 0 0 8px 0 white;
  overflow: hidden;
}
.img-panel-chat-container img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chats-container{
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 12px;

}

.chats-img-perfil-container{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #aff02e;
  box-shadow: 0 0 8px 0px black;
  cursor: pointer;
}

.chats-img-perfil-container img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.chats-container h4{
  cursor: pointer;
}

.panel-messages-card {
  padding: 12px 8px 12px 8px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.section-chat {
  max-width: 280px;
  height: 500px;
  background-color: black;
  color: white;
  padding: 24px;
  border-radius: 40px 40px 40px 0;
  box-shadow: inset 0 0 20px -4px white;
}
.header-chat-container {
  display: flex;
}
.img-chat-container-relative {
  position: relative;
  width: 100px;
  height: 70px;
  margin-right: 12px;
}
.img-chat-container {
  position: absolute;
  top: 8px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #aff02e;
  box-shadow: 0 0 12px 0 black, 0 0 16px 0px #aff02e, inset 0 0 20px -4px white;

  overflow: hidden;
}
.img-chat-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.name-chat-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.name-chat-container h2 {
  font-size: 20px;
  font-weight: 600;
  text-shadow: 0 0 4px #aff02e;
}
.name-chat-container h3 {
  font-size: 14px;
  font-weight: 400;
  color: #aff02e;
}
.chat-content {
  background-color: rgb(92, 111, 89);
  height: 272px;
  width: 100%;
  border-radius: 20px;
  box-shadow: inset 0 0 12px 0 black;
}
.messages-card {
  padding: 12px 8px 12px 8px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.messages-unit {
  margin: 4px;
  max-width: 200px;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
}
.messages-unit div {
  display: flex;
}
.messages-unit p {
  display: block;
  max-width: 168px;
  text-wrap: wrap;
  padding: 4px 8px;
  background-color: black;
  border-radius: 16px;
}
.message-unit-date{
  display: none;
  color: black;
  font-size: 12px;
}
.received-message{
  align-items: start;
}
.send-message {
  align-self: end;
  text-align: end;
}
.button-delete-message{
  margin-right: 4px;
  background-color: transparent;
  display: none;
  color:#aff02e;
  border: none;
  cursor: pointer;
}
.send-message:hover .button-delete-message{
  display: block;
}
.send-message:hover .message-unit-date{
  display: block;
}
.received-message:hover .message-unit-date{
  display: block;
}
.form-create-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.field-form-create-message {
  margin: 0 auto;
  margin-top: 8px;
  padding: 12px 14px;
  background-color: rgb(92, 111, 89);
  border-radius: 20px;
  width: 100%;
  height: 80px;
  box-shadow: inset 0 0 12px 0 black;
}
.button-send-message {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #aff02e;
  border: none;
  border-radius: 4px;
  box-shadow: inset 0 0 12px -4px black, 0 0 12px -4px #aff02e;
}
