#retune-chat-button {
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: -1px;
  right: 36px;
}
.iframe-chatbot{
  z-index: 99999;
}


@media (min-width: 1024px) {
  #retune-chat-button {
    bottom: 7px;
    right: 40px;
  }
  /* #retune-chat-window{
    opacity: 1 !important;
    z-index: 2 !important;
    pointer-events:visible;
  }
  #retune-chat-window iframe{
    opacity: 1 !important;
    z-index: 2 !important;
    pointer-events:visible;


  } */
}
