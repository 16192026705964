.config-perfil-section {
  margin: 0 auto;
  padding-top: 48px;
  max-width: 1024px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.perfil-dates-article {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.date-perfil-container {
  margin: 0 auto;
  margin: 0 6px 12px;
  padding: 12px 16px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.521);
  width: 210px;
  max-width: 300px;
  box-shadow: 0 0 12px 0px black, inset 0 0 16px 0px white;
}
.delete-account-article {
    padding: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.note-admin-perfil-container{
    margin: 0 auto;
  margin: 0 6px 12px;
  padding: 12px 16px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.521);
  width: 100%;
  max-width: 400px;
  box-shadow: 0 0 12px 0px black, inset 0 0 16px 0px white;

}
.button-delete-account {
  border: 1px solid #aff02e;
  background-color: #343033;
  color: white;
  padding: 8px 12px;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 black, inset 0 2px 4px 0 black;
  cursor: pointer;
}
