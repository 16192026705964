:root {
  --color1: #aff02e;
  --color2: #343033;
  --color3: #12b318;
  --color4: #131313;
  --color5: #b3b3b1;
  --color7: #4c4c4c;
}
.main-imageview {
  padding: 100px 8px 44px;
  background-color: var(--color2);
}
.section-image-view{
  max-width: 720px;
  margin: 0 auto;
  padding: 24px 0 36px;
}
.img-imageview {
  margin: 0 auto;
  max-height: 80vh;
  max-width: 720px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  box-shadow: 0 0 20px -8px var(--color1), 0 0 10px -4px white;

}
.img-imageview img {
  /* margin: 0 auto;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 100px); */
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;

}
.text-description-imageView-container{
  padding: 24px;
}
.text-description-imageView{
  color: white;
  text-align: center;
}