.galery {
  margin-top: 64px;
  padding-bottom: 39px;
  min-height: 100vh;
  background-color: black;
  color: white;
}

.galery-container {
  margin: 24px;
  height: calc(100vw * 9 / 16);
  background-color: white;
  color: black;
  text-align: center;
}

.videosDashboard-container {
  margin: 24px;
  height: calc(100vw * 9 / 16);
  background-color: white;
  color: black;
  text-align: center;
}
.leaflet-container {
  z-index: 1;
}

.map-container {
  margin: 24px;
  height: calc(100vw * 9 / 16);
  background-color: white;
  color: black;
  text-align: center;
  z-index: 1;
}

.map {
  height: 100%;
  width: 100%;
}

.button-container {
  padding: 0 24px 24px;
}

.button-container button {
  margin-right: 8px;
}

@media (min-width: 720px) {
  .galery {
    margin-top: 88px;
    padding: 24px 80px;

  }
  .galery-container {
    margin: 24px;
    height: calc(100vw * 9 / 16);
    background-color: white;
    color: black;
    text-align: center;
  }

  .videosDashboard-container {
    margin: 24px;
    height: calc(100vw * 9 / 16);
    background-color: white;
    color: black;
    text-align: center;
  }
}
