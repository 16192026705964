.img-profile-container{
    width: 100%;
    max-height: 700px;
    border-radius: 20px;
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img-profile{
    max-width: 100%;
    height: 100%;  
    border-radius: 20px;
}
.comments-user-container{
    padding: 28px 20px 0;
}
.card-content-other-container{
    margin: 0 auto;
    margin-bottom: 40px;
    border-radius: 20px;
    padding-top: 22px;
    box-shadow: 0 0 20px -12px white, inset 0 0 20px -8px #12b318;
    position: relative;

    padding: 0 16px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #4c4c4c;
    color: white;
    border-radius: 20px 20px 20px 20px;
}
.card-content-other-profile{
    margin: 0 auto;
    margin-top: 24px ;
    margin-bottom: 24px;
    border-radius: 20px;
    padding-top: 22px;
    box-shadow: 0 0 20px -12px white, inset 0 0 20px -8px #12b318;
    position: relative;
}
.center-number-activity{
    width: 100%;
    text-align: center;
    display: block;
    text-shadow: 0 0 12px black;
}