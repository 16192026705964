:root {
  --colorfont: #4f4f4f;
  --colorfont2: #00703e;
  --colorfont3: #fcbb45;
}
.hidden {
  position: fixed;
  top: 64px;
  width: 100%;
  height: calc(100vh - 103px);
  background-color: var(--color6);
  overflow: hidden;
  z-index: 2;
}
.box-botton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}
.overflow {
  width: 100%;
  height: calc(100vh - 107px);
  /* overflow: hidden; */
}
.certificado {
  background-color: white;
  height: 740px;
  position: relative;
  overflow: hidden;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 900;
}
.certificado-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 1040px;
  z-index: 0;
}
.svgQR-certificate{
  position: absolute;
  top: 112px;
  right: 92px;
  z-index: 1;
}
.certificado-span {
  position: absolute;
  width: 1060px;
  top: 360px;
  height: 72px;
  font-weight: bold;
  color: #222222;
  text-align: end;
  font-size: 18px;
  z-index: 1;
  /* background-color: #00703e6e; */
}
.user-name {
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
}
.hr-name {
  height: 4px;
  border-radius: 2px;
  background-color: #d09246;
  border: none;
  width: 360px;
  text-align: center;
  margin: 0 auto;
}
.certificado-documento{
  position: absolute; 
  font-weight: 500;
  top: 392px;
  right: 280px;
}
.certificado-title {
  position: absolute;
  width: 660px;
  height: 252px;
  top: 246px;
  left: 360px;
  font-size: 52px;
  line-height: 1;
  padding: 0;
  padding-top: 10px;
  border-top: 3px solid var(--colorfont3);
  border-bottom: 2px solid var(--colorfont3);
  text-align: center;
  z-index: 1;
}
.certificado-title-volunteer {
  position: absolute;
  width: 660px;
  height: 252px;
  top: 246px;
  left: 360px;
  font-size: 52px;
  padding: 0;
  padding-bottom: 20px;
  padding-left: 30px;
  border-top: 3px solid var(--colorfont3);
  border-bottom: 2px solid var(--colorfont3);
  text-align: center;
  display: flex;
  align-items: center;
  z-index: 1;
}
.coach {
  color: var(--colorfont);
  z-index: 1;
}
.ambiental {
  color: var(--colorfont2);
  z-index: 1;
}
.volunteers {
  color: var(--colorfont);
  z-index: 1;
}

.certificado-footer {
  position: absolute;
  left: 20px;
  top: 473px;
  right: 0px;
  z-index: 1;
}
.certificado-footer span {
  font-size: 22px;
  z-index: 1;
}
.firma-registro {
  position: absolute;
  top: 512px;
  left: 300px;
  width: 524px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 100;
  color: #222222;
  font-size: 9.5px;
  z-index: 1;
}
.firma {
  position: relative;
  z-index: 1;
}

.firma-name {
  position: absolute;
  width: 192px;
  top: 0px;
  left: 0px;
  font-size: 17px;
  text-align: center;
  font-style: normal;
  border-top: 2px solid var(--colorfont);
}
.firma-cargo {
  position: absolute;
  width: 192px;
  top: 25px;
  left: 0;
  font-size: 15px;
  text-align: start;
  font-style: italic;
  margin-left: 35px;
}
.registro {
  position: relative;
}
.registro span {
  position: absolute;
  width: 275px;
  top: 0px;
  left: 205px;
  font-size: 14px;
  font-style: normal;
}
