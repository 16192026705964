:root {
  --color1: #aff02e;
  --color2: #343033;
  --color3: #12b318;
  --color4: #131313;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
#root {
  position: relative;
  min-width: 320px;
  min-height: 100vh;
  min-height: 100dvh;
  background-color: black;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: black;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 2px solid #aff02e;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aff02e;
}
.color-green {
  color: var(--color1);
}
header {
  position: fixed;
  top: 0;
  background-color: var(--color4);
  z-index: 5;
  width: 100%;
  min-width: 320px;
  box-shadow: 0px 0px 12px 0px #aff02e;
  display: flex;
  align-items: center;
}

.icon-menu {
  position: relative;
  padding: 4px;
  padding-right: 12px;
  height: 64px;
  background-color: var(--colr4);
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template: "img-logo img";
  align-items: center;
  justify-content: start;
}
.logo {
  height: 92px;
  width: 92px;
  padding: 12px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color4);
  border-radius: 50%;
}
.logo img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
header img {
  height: 60px;
  grid-area: img-logo;
}
.text-header-container {
  width: calc(100vw - 112px);
  overflow: hidden;
}
.text-header-animated {
  width: 100%;
  color: white;
  display: inline-block;
  white-space: nowrap;
  /* overflow: hidden; */
  /* --text-width: auto; */
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  animation: moveText 10s linear infinite;
}
.admin-navbar-container {
  width: 40px;
}
.admin-navbar {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
}
.buttom-menu-header {
  cursor: pointer;
  grid-area: img;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  z-index: 1;
  margin-left: 8px;
  margin-top: -12px;
  transform: var(--img1);
}
.buttom-menu-header h4 {
  color: #aff02e;
  margin-top: -6px;
  font-size: 12px;
  text-align: center;
}
.buttom-menu-header-close {
  cursor: pointer;

  margin-left: 8px;
  grid-area: img;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 1;

  transform: var(--img2);
}
.open-menu {
  width: 100%;
  height: 100%;
  z-index: 10;
}
.close-menu {
  width: 24px;
  height: 24px;
  z-index: 10;
}
.dropdown {
  position: absolute;
  width: 80px;
  width: 108px;

  height: calc(100vh - 101.6px);
  left: 0;
  top: 64px;
  background-color: #000000b9;
  padding: 16px 4px;

  display: grid;
  align-content: center;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;

  clip-path: var(--clip, inset(0% 100% 0 0));
  transition: clip-path 1s;

  font-size: 12px;
  color: var(--color1);
  font-weight: bold;
  z-index: 4;
}

.dropdown svg {
  width: 100%;
  text-align: center;
  font-size: 20px;
}
main {
  height: 100%;
  overflow: auto;
  min-height: 100vh;
  min-height: 100dvh;
  position: relative;
  padding: 64px 0 37px;
}
.main-account {
  padding: 64px 0 28px;
}
a {
  text-decoration: none;
  color: var(--color1);
  animation: a-menu-link 2s linear infinite alternate;
}

ul {
  display: grid;
  align-content: center;

  gap: 4px;
}
ul li {
  list-style: none;
  width: 100%;
}

.primary-bottom {
  text-align: center;
  border-radius: 8px;
  height: 40px;
}
.primary-bottom a:hover {
  color: white;
  text-shadow: -8px 8px 4px var(--color1);
}
/* .button-logout {
  position: absolute;
  bottom: 48px;
  left: 50%;
  transform: translateX(-50%);
} */
/* .open-submenu {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
} */
.submenu {
  display: none;
  position: absolute;
  background-color: blue;
  right: 0;
  top: 26px;
  z-index: 2;
}
.submenu li {
  margin: 0;
}
.open-submenu:hover > .submenu {
  display: block;
  background-color: white;
}

footer {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0px;
  height: 38px;
  width: 100%;
  min-width: 320px;

  padding: 0 12px;
  background-color: var(--color4);
  color: var(--color1);
  font-size: 16px;
  box-shadow: 0px 0px 12px 0px #aff02e;

  z-index: 5;
}
.footer-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.chat-user-to-user-container {
  position: absolute;
  bottom: 0;
}
.icon-menu-footer {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.buttom-menu-footer {
  cursor: pointer;
  grid-area: img;
  width: 22px;
  border-radius: 50%;
  z-index: 1;
  transform: var(--img1);
}
.buttom-menu-footer-close {
  cursor: pointer;
  grid-area: img;
  width: 22px;
  border-radius: 50%;
  z-index: 1;
  transform: var(--img2);
}
.dropup-container {
  position: relative;
}
.dropup {
  position: absolute;
  width: 108px;

  right: -12px;
  top: -116px;
  background-color: #000000;
  border-radius: 18px 0 0 0;
  padding: 16px 12px;

  display: grid;
  align-content: center;
  grid-template-rows: 1fr auto;

  clip-path: var(--clip, inset(100% 0 0 100%));
  transition: clip-path 1s;

  font-size: 12px;
  color: var(--color1);
  font-weight: bold;
  z-index: 4;
}
.li-menu-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-footer-icon {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40px;
  cursor: pointer;
}
.chat-footer-icon img {
  width: 100%;
  object-fit: cover;
}
.panel-chat-container {
  position: absolute;
  bottom: 24px;
  left: 8px;
  clip-path: inset(100% 100% 0% 0%);
  transition: clip-path 0.5s;
}
.footer-ods-icon {
  height: 28px;
  cursor: pointer;
}
.footer-ods-icon img {
  height: 100%;
}
.button-chatbot {
  position: absolute;
  width: 34px;
  top: 0;
  right: 40px;
  cursor: pointer;
}
.iframe-chatbot {
  position: absolute;
  bottom: 38px;
  right: 0;
  height: calc(100vh - 102px);
  width: 100vw;
  max-width: 360px;
  background-color: #e9eef2;

  clip-path: inset(100% 0 0 100%);
  transition: clip-path 1s;
}
@media (min-width: 720px) {
  .dropdown svg {
    font-size: 20px;
  }
  .text-header-animated {
    font-weight: 400;
    font-size: 20px;
    animation: moveText720 12s linear infinite;
  }
}
@media (min-width: 1024px) {
  main {
    padding: 88px 0 60px;
  }
  .primary-bottom {
    font-size: 16px;
    /* width: 136px; */
  }
  header {
    height: 88px;
  }
  .buttom-menu-header {
    height: 60px;
    width: 60px;
  }
  .buttom-menu-header-close {
    height: 50px;
    width: 50px;
  }
  .close-menu {
    width: 28px;
    height: 28px;
  }
  .logo {
    height: 140px;
    width: 140px;
    padding: 24px;
  }
  .dropdown {
    top: 87px;
    height: calc(100vh - 147px);
    padding: 32px 4px;
    padding-bottom: 130px;
    width: 108px;
  }

  .dropdown svg {
    margin: 0px;
  }
  .text-header-container {
    width: calc(100vw - 140px);
  }
  .text-header-animated {
    font-weight: 400;
    font-size: 20px;
    animation: moveText1024 15s linear infinite;
  }
  .primary-bottom {
    display: block;
  }
  .submenu {
    top: 30px;
  }
  footer {
    height: 60px;
    /* font-size: 32px; */
  }
  .chat-footer-icon {
    width: 50px;
    top: 4px;
  }
  .panel-chat-container {
    bottom: 44px;
  }
  .chats-container h4 {
    font-size: 20px;
  }
  .messages-unit p {
    font-size: 16px;
  }
  .send-message p {
    font-size: 16px;
  }
  .section-chat-into label {
    font-size: 20px;
  }
  .button-chatbot {
    top: 15px;
    right: 44px;
  }
  .iframe-chatbot {
    height: calc(100vh - 148px);
    bottom: 60px;
  }
  .dropup {
    top: -132px;
  }
}

@keyframes moveText {
  0% {
    transform: translateX(100%);
  }
  100% {
    /* transform: translateX(-500%); */
    transform: translateX(calc(-100vw * 2));
  }
}
@keyframes moveText720 {
  0% {
    transform: translateX(100%);
  }
  100% {
    /* transform: translateX(-500%); */
    transform: translateX(calc(-60vw * 2));
  }
}
@keyframes moveText1024 {
  0% {
    transform: translateX(100%);
  }
  100% {
    /* transform: translateX(-500%); */
    transform: translateX(calc(-40vw * 2));
  }
}

@keyframes a-menu-link {
  100% {
    color: white;
  }
}
