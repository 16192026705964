:root {
  --color1: #aff02e;
  --color2: #343033;
  --color3: #12b318;
  --color4: #131313;
  --color5: #b3b3b1;
  --color7: #4c4c4c;
}

.head-perfil {
  min-height: 220px;
  margin-top: 20px;
  margin-bottom: 48px;
  padding: 0 24px;
  position: relative;
}

.img-fondo {
  margin: 0 auto;
  width: 100%;
  max-width: 1366px;
  height: calc(80vw / 2);
  max-height: 768px;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 0 20px -12px black;
}

.img-fondo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-perfil-card {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img-perfil {
  position: relative;
  border-radius: 50%;
  border: 4px solid var(--color1);
  width: 200px;
  height: 200px;
  margin: 0 auto;
}
.update-perfil {
  position: absolute;
  right: 0;
  bottom: 0;
}
.update-perfil svg {
  padding: 4px;
  font-size: 24px;
  color: black;
  background-color: var(--color1);
  border-radius: 50%;
}

.img-perfil img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.send-message-all-container {
  position: fixed;
  justify-content: center;
  top: 80px;
  z-index: 1;
}
.send-message-container {
  margin: 0 auto;
  margin-bottom: 12px;
  color: #aff02e;
  background-color: black;
  box-shadow: 0 4px 4px 0 #aff02e, inset 0 2px 4px 0 #aff02e;
  text-shadow: 0 0 12px black;
  border: 1px solid white;
  padding: 4px 8px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  cursor: pointer;

  z-index: 1;
}
.section-chat{
  clip-path: inset(0% 0% 0% 0%);
  transition: clip-path .5s;
}
.title-name-user-perfil {
  margin-top: 28px;
  color: white;
  background-color: #13131346;
  box-shadow: 0 0 20px 12px var(--color1), inset 0 0 40px 2px black;
  padding: 4px 8px 8px;
  text-align: center;
  text-shadow: 0 0 20px var(--color1);
  border-radius: 24px;
}
.second-buttom {
  background-color: var(--color2);
  color: white;
  border: 2px solid #12b318;
  font-size: 13px;
  border-radius: 4px;
  margin: 0;
  cursor: pointer;
}
.second-buttom svg {
  color: white;
  margin: 0 4px;
}

.icono {
  color: #12b318;
  margin-right: 4px;
}

.buttom {
  width: 90vw;
}

.nav-bar-img {
  margin: 0 auto;
  width: 100%;
  min-width: 288px;
  color: white;
}

.nav-bar-img ul {
  justify-content: space-between;
  background-color: var(--color5);
  padding: 0;
  margin: 0 auto;
  border-radius: 8px;
  max-width: 600px;
}
.cards {
  margin: 0 auto;
  max-width: 1024px;
  padding-top: 32px;
  position: relative;
}

.button-down-account-free {
  position: absolute;
  top: 40px;
  right: 0;
  padding: 8px 12px;
  border-radius: 20px;
  border: 1px solid var(--color1);
  box-shadow: 0 4px 8px 0 black, inset 0 2px 8px 0 black;
  z-index: 1;
}

.card-content {
  margin: 0 auto;
  padding: 0 16px 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #4c4c4c;
  color: white;
  border-radius: 20px 20px 20px 20px;
}
.ods-card-activity{
  margin-top: 16px;
  width: 240px;
  cursor: pointer;
  z-index: 2;
}
.ods-card-activity img{
  width: 100%;
}
.activity-id {
  /* opacity: 0; */
  opacity: 1;
  /* animation: appear 2s ease-in-out forwards; */
  transition: opacity 1.5s ease-in-out;
  position: relative;
}

.activity-id.active {
  opacity: 1;
}
.arrow-down-container {
  border-radius: 12px;
  height: 40px;
  margin: 0 auto;
}
.arrow-down-activity {
  font-size: 48px;
  margin: 12px;
  color: #12b318;
}

.activity {
  position: relative;
  /* border: 4px solid var(--color3); */
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 24px;
  padding: 40px 4px 4px;
}
.hr-activities {
  margin-bottom: 12px;
}

.activity-head {
  background-color: var(--color2);
  color: var(--color1);
  width: calc(100% - 8px);
  height: 68px;
  border-radius: 40px 40px 0px 0px;
  padding: 4px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 4px;

  z-index: -1;
}

.activity-text {
  min-width: 240px;
  padding: 12px;
  z-index: 1;
}

.button-certificado-volunteers {
  border: 1px solid white;
  border-radius: 20px;
  box-shadow: 0px 4px 4px 0 black, inset 0 2px 4px 0 black;
  padding: 8px 12px;
  text-align: center;
}

.activity-images-n1 {
  margin: 0 auto;
  min-width: 300px;
  height: 200px;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
}

.activity-images-n2 {
  margin: 0 auto;
  width: 300px;
  min-width: 300px;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
}

.activity-images-n3 {
  margin: 0 auto;
  width: 300px;
  min-width: 300px;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
}

.number-activity {
  color: var(--color3);
  font-weight: bold;
}

.text-activity {
  font-size: 14px;
  margin-bottom: 16px;
  margin-top: 16px;
  text-shadow: 0 0 12px black;
}

.mark-bottom {
  background-color: var(--color7);
  color: var(--color1);
  border-radius: 25px;
  text-align: center;
  border: 1px solid var(--color3);
  width: 150px;
  height: 26px;
  position: absolute;
  bottom: -14px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.mark-volunteers {
  letter-spacing: 2px;
  font-size: 12px;
}

.pdf {
  margin: 0 auto;
  margin: 44px 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.note-admin {
  background-color: rgba(255, 255, 255, 0.26);
  color: #c4ff00;
  padding: 8px 16px;
  width: 100%;
  margin-bottom: 24px;
  border-radius: 18px;
  box-shadow: 0 0 20px -4px #c4ff00, 0 0 40px 0px black;
}

.certificado-buttom {
  margin: 0 auto;
  text-decoration: none;
  padding: 8px;
  border-radius: 20px;
  width: 140px;
  box-shadow: 0px 2px 4px 0px black, inset 0 2px 4px 0 black;
}

.imgspng {
  position: relative;
  margin: 0 auto;
  width: 260px;
}

.card-imgpng {
  position: absolute;
}
.card-imgpng-n2 {
  position: relative;
  margin: 0 auto;
}

.hexagon-1,
.hexagon-2,
.hexagon-3,
.hexagon-4,
.hexagon-5,
.hexagon-6,
.hexagon-7,
.hexagon-8,
.hexagon-9 {
  z-index: 2;
}

.uppng-n1 {
  left: 93px;
}

.uppng-n2 {
  position: absolute;
  width: 262px;
  display: flex;
  justify-content: space-between;
}

.uppngimg-n2 {
  width: 253px;
}

.uppng-n2 img:nth-child(2) {
  margin-left: 31px;
  margin-right: 31px;
}

.uppng-n3 {
  position: absolute;
}

.centerpng-n1 {
  top: 49px;
}

.centerpng1-n2 {
  position: absolute;
  top: 29px;
  display: flex;
  justify-content: space-between;
  width: 166px;
}

.centerpng1-n3 {
  position: absolute;
  top: 29px;
  display: flex;
  justify-content: space-between;
  width: 166px;
}

.centerpng1img-n2 {
  width: 157px;
}

.centerpng2-n2 {
  position: absolute;
  top: 58px;
}

.centerpng2-n3 {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 266px;
  top: 58px;
}

.centerpng2img-n3 {
  width: 257px;
}

.centerpng3-n2 {
  position: absolute;
  top: 86px;
  display: flex;
  justify-content: space-between;
  width: 166px;
}

.centerpng3-n3 {
  position: absolute;
  top: 86px;
  display: flex;
  justify-content: space-between;
  width: 166px;
}

.centerpng3img-n3,
.centerpng1img-n3 {
  width: 157px;
}

.centerpng3img-n2 {
  width: 157px;
}

.centerpng-n3 {
  top: 49px;
}

.botpng-n1 {
  top: 98px;
  left: 33px;
  display: flex;
  justify-content: space-between;
  width: 232px;
}

.botpng-n2 {
  position: absolute;

  top: 116px;
  left: 50%;
  transform: translateX(-50%);
}

.botpng-n3 {
  position: absolute;
  top: 116px;
}

.botpng-n1 img:nth-child(2) {
  position: absolute;
  left: 174px;
}

.botpng-n3 img:nth-child(2) {
  position: absolute;
  left: 174px;
}

.card-imgpng img {
  width: 58px;
  cursor: pointer;
  z-index: 2;
}

.card-imgpng-n2 img,
.card-imgpng-n3 img {
  width: 66.88px;
  height: 58px;
  cursor: pointer;
  z-index: 2;
}

.image-n1 img {
  margin-top: 5px;
  height: 58px;
  width: 58px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
}

.image-n2 img {
  height: 58px;
  width: 58px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
}

.image-n3 img {
  height: 58px;
  width: 58px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 720px) {
  .head-perfil h1 {
    font-size: 32px;
    text-align: center;
  }
  .img-perfil-card {
  }

  .card-content {
    justify-content: space-evenly;
    padding: 0 48px 48px;
  }
  .button-down-account-free {
    right: 80px;
  }
}
@media (min-width: 1024px) {
  body,
  .second-buttom {
    font-size: 16px;
  }
  .send-message-all-container{
    top: 100px;
  }
  .head-perfil {
    margin-top: 0px;
    padding-top: 20px;
  }
  .head-perfil h1 {
    margin-bottom: 24px;
    font-size: 36px;
  }

  .img-fondo {
    width: 100%;
    height: calc(80vw / 2);
  }
  .img-perfil-card {
  }
  .img-perfil {
    width: 300px;
    height: 300px;
  }
  .activity {
    max-width: 920px;
  }

  .activity p {
    font-size: 18px;
  }

  .card-content {
    justify-content: space-evenly;
    align-items: center;
    padding: 0 80px 48px;
  }

  .activity-images-n1 {
    position: relative;
    width: 450px;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .uppng-n1 {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .card-imgpng img {
    width: 76px;
    cursor: pointer;
    z-index: 2;
  }

  .image-n1 img {
    margin-top: 6px;
    height: 76px;
    width: 76px;
  }

  .centerpng-n1 {
    top: 50%;
    transform: translateY(-50%);
  }

  .centerpngimg-n1 {
    margin-top: -3px;
  }

  .botpng-n1 {
    top: 129px;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    display: flex;
    justify-content: space-between;
  }

  .botpng-n1 img:nth-child(2) {
    position: static;
  }

  .activity-images-n2 {
    width: 450px;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-imgpng-n2 img {
    width: 87.63px;
    height: 76px;
    cursor: pointer;
  }

  .image-n2 img {
    height: 74px;
    width: 74px;
  }

  .uppng-n2 {
    top: 0;
    width: 350px;
  }

  .uppngimg-n2 {
    width: 337px;
  }

  .centerpng1-n2 {
    top: 38px;
    width: 218px;
  }

  .centerpng1img-n2 {
    width: 205px;
  }

  .centerpng2-n2 {
    top: 76px;
  }

  .centerpng3-n2 {
    top: 114px;
    width: 218px;
  }

  .centerpng3img-n2 {
    width: 205px;
  }

  .botpng-n2 {
    top: 152px;
  }

  .activity-images-n3 {
    width: 450px;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-imgpng-n3 img {
    width: 87.63px;
    height: 76px;
    cursor: pointer;
  }

  .image-n3 img {
    height: 74px;
    width: 74px;
  }

  .uppng-n3 {
    top: 0;
  }

  .centerpng1-n3 {
    top: 38px;
    width: 220px;
  }

  .centerpng1img-n3 {
    width: 205px;
  }

  .centerpng2-n3 {
    top: 76px;
    width: 352px;
  }

  .centerpng2img-n3 {
    width: 337px;
  }

  .centerpng3-n3 {
    top: 114px;
    width: 220px;
  }

  .centerpng3img-n3 {
    width: 205px;
  }

  .botpng-n3 {
    top: 152px;
  }
}

@media (min-width: 1440px) {
  
  .img-perfil-card {
  }
  .activity-head {
    font-size: 28px;
  }
  .activity-text {
    max-width: 720px;
  }

  .mark-bottom {
    height: 40px;
    width: 160px;
    bottom: -20px;
  }
}
