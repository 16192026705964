.ods-activities-container{
    position: relative;
    margin-top: 64px;
    padding: 56px 0 40px;
    min-height: calc(100vh - 98px);
    background-color: #343033;
    color: white;
}
.ods-activities-container h2{
    position: fixed;
    top: 64px;
    color:#aff02e;
    padding: 12px 24px;
    width: 100%;
    background-color: #343033;
}
.ods-activity-card{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    padding: 24px;
    margin-bottom: 24px;
    background-color: rgba(255, 255, 255, 0.126);
}
.ods-activity-image{
    margin: 0 auto;
    margin-bottom: 16px;
    width: 160px;
    min-width: 160px;
    height: 160px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 0 12px -4px black;
}

.ods-activity-card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.ods-activity-card p{
    min-width: 252px;
    max-width: 500px;
}
.ods-activity-card span{
    color: #aff02e;
    font-weight: bold;
    font-style: italic;
}

@media(min-width: 1024px){
.ods-activities-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 88px;   
    padding: 68px 0 72px;
}
.ods-activities-container h2{
    top: 88px;
    text-align: center;
    box-shadow: 0 0 8px 0px black;
}
.ods-activity-card{
    width: 400px;
    margin: 12px 0;
    border-radius: 20px;
    box-shadow: 0 0 8px -2px white, inset 0 0 8px -2px black;
}
}