.envelope {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 50;
  }
  
  .flap {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50%;
    background-color: black;
    transform-origin: top;
    transition: transform 1s ease-in-out;
  }
  .flap-down{
    position: absolute;
    width: 100%;
    height: 50%;
    background-color: black;
    transform-origin: bottom;
    transition: transform 1s ease-in-out;
    z-index: 2;
  }
  .text-enter-container{
    
    margin: 0 auto;
    width: 100%;
    max-width: 720px;
    padding: 24px 12px 0;
    text-align: center;
    color: white;
    font-size: 20px;
  }
  .text-enter-container h2{
    text-shadow: 0 0 8px white;
    margin-bottom: 80px;
  }
  .text-enter-container img{
    width: 100%;
    margin-top: 12px;
  }
  .span-click-intro{
    margin: 0 auto;
    max-width: 500px;
    text-align: center;
    margin-top: 0px;
    padding: 0 24px;
  }
  .span-click-intro img{
    position: relative;
    width: 100%;
    z-index: 3;
  }
  #flapTop {
    top: 0;

  }


  #flapBottom {
    bottom: 0;
  }

  .open-button {
    position: absolute;
    left: 50%;
    bottom: -82px;
    background-color: black;
    transform: translate(-50%);
    width: 180px;
    height: 180px;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid #c4ff00;
    z-index: 3;
    box-shadow: 0 0 40px 16px white;
    animation: button-enter-animation 0.7s linear infinite alternate;
  }
  .button-icon-logo{
    position: relative;
    border: 1px solid chartreuse;
    border-radius: 50%;
    margin: 0 auto;
    width: 156px;
    height: 156px;
    box-shadow: inset 0 0 40px -4px #c4ff00;
    animation: button-enter-animation2 0.7s linear infinite alternate;

  }
  .open-button img{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 4px;
    width: 80%;
    
  }
  .opened #flapTop {
    transform: translateY(-150%);
  }
  
  .opened #flapBottom {
    transform: translateY(100%);
  }
  
  .opened .content {
    display: none;
  }

  @keyframes button-enter-animation {
    100%{
      box-shadow: 0 0 40px 16px #c4ff00;
      
    }
  }

  @keyframes button-enter-animation2 {
    100%{
      box-shadow: inset 0 0 40px -4px white;
      
    }
  }

