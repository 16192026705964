.main-accountfree {
  background-color: #343033;
  padding: 64px 0 38px;
}
.accountfree-container {
  padding: 12px 12px 32px;
}
.card-imgpng-free {
  margin: 0 auto;
  display: block;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 4px solid #aff02e;
  margin-bottom: 24px;
  box-shadow: 0 0 20px -4px white, 0 0 20px 4px black;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.tarea-user-container{
  background-color: rgba(54, 54, 54, 0.57);
  width: 100%;
  border-radius: 12px;
  padding: 12px;
  color: white;
  border: 1px solid #12b318;
  box-shadow: 0 0 20px -8px black, 0 0 10px -4px white, inset 0 0 40px -4px black;
}
.imagen-fondo-tarea{
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
  filter: blur(10px);
  opacity: .4;
}
.card-imgpng-free-container {
  width: 100%;
  position: relative;
  margin-bottom: 12px;
}
.card-imgpng-free img {
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}
.title-activity-account{
  text-shadow: 0 0 18px black;
}

.span-more-low-container {
  display: flex;
  justify-content: flex-end;
}
.icon-more-activity-container{
  font-size: 28px;
  cursor: pointer;
}
.span-more-low {
  text-align: end;
  font-size: 22px;
}
.video-activities-container{
  margin: 12px 0;
  border-radius: 40px;
  overflow: hidden;
  background-color: black;
  box-shadow: 0 0 12px 0 black;
}
.video-activity {
  width: 100%;
}
.description-container {
  width: 100%;
  padding: 0 12px 12px;
  position: relative;
  z-index: 1;
}
.description-container p {
  text-align: justify;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    top: -500px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@media(min-width: 720px){
  .card-imgpng-free {
    width: 400px;
    height: 400px;
    border-radius: 50%;
    border: 4px solid #aff02e;
    margin-bottom: 24px;
  }
  .tarea-user-container{
    padding: 40px;
  }

}
@media(min-width: 1024px){

  .card-imgpng-free{
    width: 500px;
    height: 500px;
  }
}