:root {
  --color1: #aff02e;
  --color2: #343033;
  --color3: #12b318;
  --color4: #131313;
  --color5: #b3b3b1;
  --color6: #0e1215;
}
.body {
  background-color: var(--color6);
  min-height: calc(100vh - 99px);
}
.main {
  background-color: var(--color6);
  color: white;
  position: relative;
  height: 100%;
}
.section-signup {
  padding: 48px 0;
  margin: 0 auto;
  max-width: 560px;
}
.title-registro {
  display: block;
  font-size: 30px;
  text-align: center;
  color: var(--color1);
  margin-bottom: 16px;
}

.input-label-signup {
  display: flex;
  flex-direction: column;
  width: 220px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 8px;
}
.img-icon {
  background-color: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2px;
  overflow: hidden;
}
.world {
  right: 0;
}
.input-label-signup .icono {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.label-signup {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  height: 100%;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  pointer-events: none;
}
.input-signup {
  background-color: white;
  height: 32px;
  text-align: center;
  border-radius: 20px;
  border: 0;
  animation: input-animate-signup 2s linear infinite alternate;
}
.input-signup:focus {
  outline: none;
  border-bottom: 2px solid #8bc34a;
}

.input-signup:focus + .label-signup,
.input-signup:valid + .label-signup {
  transform: translate(120px, 0px);
  font-size: 12px;
  transition: 0.6s;
  color: white;
}

.error-message {
  /* position: absolute; */
  /* right: 12px;
    top: 50%;
    transform: translateY(-50%); */
  color: var(--color1);
  font-size: 14px;
}
.error-message-country {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: red;
  font-size: 20px;
}

.button-enter-signup {
  width: 220px;
  height: 32px;
  margin: 0 auto;
  border-radius: 20px;
  border: 0;
  text-align: center;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color1);
  color: white;
  cursor: pointer;
}
.button-enter-signup:hover {
  background-color: white;
  color: var(--color1);
}
.terminosycondiciones {
  display: block;
  text-decoration: underline;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  margin-top: 12px;
}

.button-close-conditions {
  position: absolute;
  bottom: 52px;
  left: 50%;
  transform: translateX(-50%);
  width: 48px;
  cursor: pointer;
  z-index: 2;
}
.button-close-conditions svg {
  width: 100%;
  color: #aff02e;
  font-size: 28px;
}
@media (min-width: 422px) {
  body {
    min-height: calc(100vh - 60px);
  }
}

@media (min-height: 600px) {
  .section-signup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 720px) {
  .input-label-signup {
    width: 280px;
  }
}

@media (min-width: 1024px) {
  body {
    min-height: calc(100vh - 800px);
  }
  .button-close-conditions {
    bottom: 76px;
  }
}

@keyframes input-animate-signup {
  0% {
    box-shadow: inset 0 0 24px -8px var(--color1);
  }
  100% {
    box-shadow: inset 0 0 20px 0px black;
  }
}
