.section-perfil{
    padding: 48px 12px;
}
.buttons-perfil{
    width: 300px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
}
.buttom-back-profile{
    border-radius: 50%;
    color: #343033;
    background-color: #aff02e;
    border: 4px solid;
    line-height: 47px;
    font-size: 32px;
    text-align: center;
    width: 56px;
    height: 56px;
    box-shadow: 0 0 12px 0px #aff02e;
}
.buttom-send-profile{
    border-radius: 50%;
    color: #343033;
    background-color: #aff02e;
    border: 4px solid;
    line-height: 47px;
    font-size: 32px;
    text-align: center;
    width: 56px;
    height: 56px;
    box-shadow: 0 0 12px 0px #aff02e;
    margin: 0 auto;
}
.buttons-perfil a{
    margin: 0 auto;
}
.input-image-file{
    width: calc(100% - 8px);
}

@media (min-width: 1024px){
    .section-perfil{
        padding: 28px 12px 24px;
    }
}