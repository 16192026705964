.presentation-container {
  margin-top: 64px;
  padding-bottom: 38px;
  background-color: #303030;
  color: white;
}

.presentation-article-1 {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(1.5fr 1fr)); */
  margin: 0 auto;
}
.article-1-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 102px);
  min-height: 500px;
  min-width: 320px;
}
.img-container-presentation {
  margin: 0 auto;
  width: 100%;
  max-width: 420px;
}
.img-container-presentation img {
  width: 100%;
}
.text-article-1-left {
  text-align: center;
  margin: 0 auto;
  max-width: 420px;
}
.text-article-1-left h2 {
  font-size: 28px;
  margin: 4px 0;
}
.text-article-1-left p {
  font-size: 16px;
  padding: 12px;
}
.article-1-right {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 102px);
  min-height: 500px;
  min-width: 320px;
}
.img-container-background-presentation {
  width: 100%;
  height: calc(100vh - 102px);
  min-height: 500px;
  z-index: 1;
}
.img-container-background-presentation img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.text-article-1-right {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 2;
}
.text-article-1-right-h2 {
  background-color: #303030b4;
  width: 100%;
}
.text-article-1-right h2 {
  margin: 0 auto;
  text-align: center;
  max-width: 420px;
  font-size: 20px;
  padding: 16px 0;
}
.supported-container {
  background-color: #303030b4;
  padding: 16px 24px;
  font-size: 12px;
  font-style: italic;
  width: 100%;
}
.supported-container p {
  max-width: 420px;
  margin: 0 auto;
}
.img-supported-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  padding: 16px 20px 8px;
  gap: 16px;
  max-width: 420px;
  margin: 0 auto;
}
.supported-container img {
  width: 100%;
  max-width: 84px;
  margin: 0 auto;
}

.presentation-article-2 {
  position: relative;
}
.forum-video-container {
  width: 100%;
  height: calc(100vh - 102px);
}
.forum-video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.forum-logo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
}
.forum-logo-container img {
  width: 100%;
}

.presentation-article-3 {
  position: relative;
}
.volunteers-logo-container {
  position: absolute;
  background-color: #303030;
  bottom: 0;
  width: 100%;
  z-index: 2;
}
.volunteers-logo-relative {
  position: relative;
  display: flex;
  align-items: center;
  height: 28vh;
}
.volunteers-logo-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
}
.sub-text-volunteers-container {
  position: absolute;
  bottom: 0;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  padding: 20px 12px;
}
.volunteers-img-container {
  background-color: #3030307e;
  max-width: 120px;
  width: 100%;
}
.volunteers-logo-container img {
  width: 100%;
}
.sub-text-volunteers-container p {
  background-color: #3030307e;
}
.sub-text-volunteers-container p span {
  color: #5ff9fc;
  font-weight: bold;
}
.presentation-article-4 {
  width: 100%;
}
.img-container-article-4 {
  display: none;
  height: calc(100vh - 102px);
}
.img-container-article-4 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.sub-text-article-4 {
  position: relative;
  height: calc(100vh - 102px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

.text-article-4-width {
  z-index: 2;
  width: 100%;
  background-color: #3030307e;
}
.text-article-4 {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  gap: 12px;
  padding: 20px px;
  max-width: 60%;
}
.text-article-4 p {
  max-width: 60%;
}
.text-article-4 span {
  color: #5ff9fc;
}
.img-text-article-4 {
  max-width: 200px;
}
.img-text-article-4 img {
  width: 100%;
}
.numbers-text-article-4 {
  margin: 0 auto;
  display: flex;
  justify-content: space-around;

  flex-wrap: wrap;
  padding: 20px 40px;
  gap: 12px;
  text-align: center;
  color: #5ff9fc;
  z-index: 2;
}
.numbers h2 {
  font-size: 20px;
}
.numbers p {
  font-size: 14px;
}
.icons-text-article-4 {
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 20px;
  background-color: #3030307e;
  width: 100%;
  z-index: 2;
}
.img-icons-article-4 img {
  width: 100%;
  max-width: 100px;
}
.background-sub-text-article-4 {
  position: absolute;
  top: 0;
  height: calc(100vh - 102px);
  width: 100%;
}
.background-sub-text-article-4 img {
  height: 100%;
  width: 100%;
  filter: grayscale(100%);
  object-fit: cover;
}

.presentation-article-5 {
  position: relative;
  background-color: #303030;
  color: black;
  padding-top: 20px;
  padding-bottom: 200px;
  height: calc(100vh - 102px);
}
.text-article-5 {
  position: relative;
  margin: 0 12px;
  background-color: #d8ddda;
  bottom: 0;
  z-index: 3;
}
.p-text-article-5 {
  padding: 4px 8px;
}
.p-text-article-5 span {
  color: #007603;
  font-weight: bold;
}
.p-text-article-5:nth-child(2) span {
  color: black;
}
.p-text-article-5:nth-child(3) span,
.p-text-article-5:nth-child(4) span {
  color: #0298cd;
}
.p-text-article-5:nth-child(5) span {
  color: #6aad0f;
}
.footer-article-5 {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: end;
  width: 100%;
}
.footer-content-article-5 {
  position: relative;
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.text-footer-article-5 {
  align-self: center;
  position: relative;
  z-index: 3;
  color: white;
  padding-left: 24px;
}
.text-footer-article-5 h2 {
  font-size: 18px;
}
.img-container-footer-article-5 {
  align-self: flex-end;
  max-width: 256px;
  width: 80%;
  z-index: 2;
}
.img-container-footer-article-5 img {
  width: 100%;
  display: none;
  z-index: 2;
}
.img-background-container-footer-article-5 {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.img-background-container-footer-article-5 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(60%);
}

.img-iconos-material-apoyo {
  display: flex;
  justify-content: space-evenly;
  padding: 12px;
  height: 100px;
}
.img-container-iconos-material-apoyo {
  height: 100%;
}
.img-iconos-material-apoyo img {
  height: 100%;
  object-fit: contain;
}
@media (min-width: 768px) {
  .volunteers-logo-relative {
    height: 24vh;
  }
  .sub-text-volunteers-container {
    padding: 20px 80px;
  }
  .sub-text-volunteers-container p {
    font-size: 18px;
  }

  .p-text-article-5:nth-child(5) {
    max-width: 400px;
  }
  .text-footer-article-5 h2 {
    font-size: 24px;
    top: 80%;
  }

  .img-container-footer-article-5 img {
    display: block;
  }

  .img-iconos-material-apoyo {
    width: 50vw;
  }
}

@media (min-width: 1024px) {
  .presentation-container {
    padding-top: 88px;
    margin-top: 0;
    padding-bottom: 60px;
  }
  .presentation-article-1 {
    grid-template-columns: 2fr 1fr;
  }
  .article-1-left {
    height: calc(100vh - 148px);
  }
  .article-1-right {
    height: calc(100vh - 148px);
  }
  .img-container-background-presentation {
    height: calc(100vh - 148px);
  }
  .volunteers-logo-relative {
    height: 28vh;
  }
  .sub-text-volunteers-container {
    padding: 48px 0px;
    max-width: 1024px;
    left: 50%;
    transform: translateX(-50%);
  }
  .forum-video-container {
    height: calc(100vh - 148px);
  }
  .presentation-article-4 {
    display: flex;
  }
  .img-container-article-4 {
    display: block;
    width: 700px;
    height: calc(100vh - 148px);
  }
  .sub-text-article-4 {
    height: calc(100vh - 148px);
  }
  .background-sub-text-article-4 {
    height: calc(100vh - 148px);
  }

  .presentation-article-5 {
    height: calc(100vh - 148px);
  }
  .p-text-article-5 {
    font-size: 20px;
  }
  .p-text-article-5:nth-child(3) {
    max-width: 750px;
  }
  .p-text-article-5:nth-child(5) {
    max-width: 700px;
  }
  .img-container-footer-article-5 {
    max-width: 460px;
    z-index: 4;
  }
}
@media (min-width: 1920px) {
  .text-article-1-left {
    max-width: 600px;
  }
  .article-1-left h2 {
    font-size: 52px;
  }
  .article-1-left p {
    font-size: 28px;
  }
  .text-article-1-right h2 {
    font-size: 44px;
    max-width: 600px;
  }
  .supported-container p {
    max-width: 600px;
    font-size: 20px;
  }
  .img-supported-container {
    max-width: 600px;
  }
  .supported-container img {
    max-width: 120px;
  }
  .volunteers-logo-relative {
    height: 24vh;
  }
  .sub-text-volunteers-container {
    padding: 20px 0;
  }
  .volunteers-img-container {
    max-width: 200px;
  }
  .sub-text-volunteers-container p {
    font-size: 30px;
  }
  .text-article-4 p {
    font-size: 40px;
  }
  .img-text-article-4 {
    width: 300px;
    max-width: 400px;
  }
  .numbers-text-article-4 {
    gap: 60px;
  }
  .numbers h2 {
    font-size: 72px;
  }
  .numbers p {
    font-size: 40px;
  }
  .icons-text-article-4 {
    gap: 60px;
  }
  .img-icons-article-4 img {
    max-width: 200px;
  }

  .p-text-article-5 {
    font-size: 40px;
    padding: 24px 48px;
  }
  .p-text-article-5:nth-child(3),
  .p-text-article-5:nth-child(5) {
    max-width: none;
  }
  .img-container-footer-article-5 {
    max-width: 800px;
  }
  .text-footer-article-5 h2 {
    top: 86%;
    left: 48px;
    font-size: 60px;
  }
  .img-iconos-material-apoyo {
    width: 60vw;
    height: 140px;
  }
}
