:root {
  --color1: #aff02e;
  --color2: #343033;
  --color3: #12b318;
  --color4: #131313;
  --color5: #b3b3b1;
  --color7: #4c4c4c;
}
.main-amdin-page {
  background-color: var(--color4);
  color: white;
}

.main-content-card-admin {
  margin: 60px 4px;
  padding-bottom: 60px;
  border: 1px solid var(--color2);
  border-radius: 8px;
}

.main-content-card h4 {
  padding: 8px 12px;
}
.section-admin-page {
  margin: 148px 24px 0;
}
.card-tareas {
  margin-bottom: 48px;
}
.title-activity-admin {
  padding: 8px 12px;
}
.img-card-tareas {
  width: 100%;
}
.img-card-tareas img {
  width: 100%;
}
.description-card-tareas {
  border: 1px solid var(--color2);
  margin: 0 auto;
  border-radius: 8px;
  max-width: 600px;
  padding: 8px 16px;
  text-align: justify;
  margin-bottom: 12px;
}
.description-card-tareas h4 {
  padding: 0px;
  font-size: 20px;
}
.resp-task18-admin{
  border: 1px solid var(--color1);
  padding: 12px 16px;
  border-radius: 20px;
  margin: 0 12px 20px;
}
.description-card-tareas p {
  font-size: 18px;
}
.comments-activities-admin {
  margin: 0 auto;
  max-width: 600px;
  font-size: 18px;
  padding-left: 8px 16px;
}
hr {
  border: 1px solid var(--color1);
}

.bottom-send {
  margin: 12px 0;
  padding: 4px;
  text-align: center;
  border-radius: 8px;
  border: none;
  background-color: var(--color1);
}
